/**
 * The transciption is on.
 *
 * @type {String}
 */
export const ON = 'on';

/**
 * The transciption is off.
 *
 * @type {String}
 */
export const OFF = 'off';
