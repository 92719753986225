/**
 * The audio type.
 */
export const AUDIO = 'audio';

/**
 * The presenter type.
 */
export const PRESENTER = 'presenter';

/**
 * The video type.
 */
export const VIDEO = 'video';
